import React from 'react'

import './__styles__/empty.module.scss'

const EmptyState = ({ type }) => (
  <div styleName="container">
    <p>{`There are no ${type} for this activity`}</p>
  </div>
)

export default EmptyState
