import React from "react";
import { Link } from "gatsby";
import { Section, Container } from "./layout.jsx";
import Empty from "./empty";

import "./__styles__/related.module.scss";

const Related = ({ data }) => (
  <Section>
    <Container>
      <h3 styleName="title">Related Activities</h3>
    </Container>
    {data.map(activity => (
      <div key={activity.id} styleName="row">
        <Container>
          <div styleName="container">
            <Link to={`/${activity.slug}`} styleName="link">
              {activity.identifier} {activity.name}
            </Link>
            {!!activity.intro && (
              <p styleName="intro">{activity.intro.intro}</p>
            )}
          </div>
        </Container>
      </div>
    ))}
    {!data.length && <Empty type="Related Activities" />}
  </Section>
);

export default Related;
